<template>
    <button type="button" class="btn btn-outline-primary" @click="copyFeedList">Copy feed API's</button>
</template>

<script>

export default {
    name: 'FeedList',
    props: {
        partnerId: String,
        feeds: Array
    },
    data(){
        return {

        };
    },
    methods: {
        copyFeedList: function() {
            const el = document.createElement('textarea');
            el.value = this.generateFeedListText();
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        generateFeedListText: function(){
            var bigString = '';
            
            this.feeds.forEach(element => {
                bigString = (bigString + element.name + ':\t' + 'https://publisher-api.footylight.com/api/' + this.partnerId + "/feed/" + element.id + '\n');
            });
            console.log(bigString);
            return bigString;
        }
    }
}
</script>

<style>

</style>