<template >
  <div v-if="passwordMatched" id="partner" class="container">
    <h1>Footylight {{ partnerName }} Feed</h1>
    <FeedList :partnerId="partnerId" :feeds="feeds" />
    <div class="card mb-3 col-sm-4">
      <div class="card-body">
        <select
          id="feedbox"
          class="form-select form-select-sm"
          v-model="feed"
          @change="getData"
        >
          <option v-for="f in feeds" :key="f.id" :value="f.id">
            {{ f.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col" v-for="vid in videos" :key="vid.id">
        <div class="card shadow-lg rounded h-100">
          <video class="card-img-top" :poster="vid.thumb" controls>
            <source :src="extractMp4(vid.embed_code)" type="video/mp4" />
          </video>
          <!-- <img class="card-img-top" :src="vid.thumb"> -->
          <a
            type="button"
            class="btn btn-primary text-light p-0"
            :href="vid.embed_url"
            target="_blank"
          >
            Launch Demo
          </a>
          <h5 class="card-header">{{ vid.title }}</h5>
          <div class="alert alert-info p-0" role="alert">
            Published on: {{ vid.published_on | moment("MMMM D, YYYY") }}
          </div>

          <div class="card-body">
            <!-- <div class="form-group">
              <strong>Embed url (for iframe)</strong>
              <textarea class="form-control" rows="2" v-model="vid.embed_url" />
            </div> -->

            <div>
              <strong>Keywords</strong><br />
              <span
                v-for="kw in vid.keywords"
                class="badge rounded-pill bg-info text-light m-1"
                :key="kw + Math.random()"
                >{{ kw }}</span
              >
            </div>

            <!-- <strong>Tags</strong><br/>
          <span v-for="tg in vid.tags" class="badge bg-secondary m-1" :key="tg.TagValue">{{tg.TagValue}}</span> -->
          </div>

          <div class="card-footer">
            <div class="form-group">
              <strong
                >Embed code
                <button
                  @click="copyToCB"
                  :id="`${vid.id}`"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-1"
                >
                  Copy
                </button></strong
              >
              <textarea
                :ref="`${vid.id}_code`"
                class="form-control"
                rows="3"
                v-model="vid.embed_code"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="container text-center mt-4">
    <h5>Enter Password</h5>
    <input
      type="password"
      name="partner_password"
      v-model="password"
      class="form-control"
      style="width: 60%; margin: auto"
    />
    <button class="btn btn-success mt-2 mb-5" v-on:click="checkPassword">
      Submit
    </button>
    <h4 class="card-title mb-5">Interested to give it a try? Contact Us</h4>
    <div
      class="card"
      style="
        margin: auto;
        width: 80%;
        border: 1px solid #ccc;
        background-color: #f4f4f4;
        padding: 20px;
        margin-bottom: 10px;
      "
    >
      <div class="card-body">
        <div class="row">
          <input
            type="email"
            class="form-control"
            placeholder="Name"
            value="firstName"
            v-model="firstName"
            style="width: 48%; margin: 1%"
          />
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            v-model="email"
            style="width: 48%; margin: 1%"
          />
        </div>
        <div class="row">
          <input
            type="email"
            class="form-control"
            placeholder="Company Name"
            v-model="companyName"
            style="width: 48%; margin: 1%"
          />
          <input
            type="email"
            class="form-control"
            placeholder="Website URL"
            v-model="websiteUrl"
            style="width: 48%; margin: 1%"
          />
        </div>
      </div>
      <textarea
        class="form-control mt-3"
        id="exampleFormControlTextarea1"
        rows="3"
        placeholder="Message"
        v-model="message"
      ></textarea>
      <div style="align-items: center">
        <button
          class="btn btn-success mt-4"
          style="width: 15%"
          v-on:click="sendMail"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import axios from "axios";
Vue.use(VueCryptojs);
Vue.use(require("vue-moment"));
import $ from "jquery";
import FeedList from "./FeedList.vue";

export default {
  name: "Partner",
  props: {
    partnerKey: String,
  },
  components: {
    FeedList,
  },
  data() {
    return {
      passwordMatched: false,
      password: "",
      partnerId: "",
      feed: "latest",
      videos: [],
      partnerCodes: {
        footylight:
          "U2FsdGVkX1+I2i8RcYLZsMAf2940kvhSoP+5ThFtdIMP+FiUqOo7C+ngT-fl-pSk8RXrGEesAwArJ2fPlgrgRnDuM5e0lnMm37ofFuOgJR-fl-oxM=",
        pavilion:
          "U2FsdGVkX19Pu0ae6Y7unkNA7oFNHsqQ0tk7-fl-RZCDjZXhn8Xt90h0hkFu6v2npv3rqhpSsS-fl-iAQr9d9HSQIt7N0LCCNwDXWX1RDrMcqBvJo=",
        yoursoccerdose:
          "U2FsdGVkX19iVwxIrli+TCFmMhERcKbsFQfXmr3vfWr8bS3eWAdb-fl-m+S7HwbkrQD2Ltz+ogLTWydAIWa8SEpF7lRYL4pawS80++lNrr4i4JzTbFx5I1S9tOIJU1ffZ-fl-8",
        soccercatch:
          "U2FsdGVkX1+ohrqFJuilkXUCSzdQJVyc6k7YtpXsnnQ6cx4kageynN9HYAG-fl-vVdyxNS68K-fl-b-fl-FkbX5AWBz8F8cJww6Ip35iAqM-fl-yX4mRLJlcJE4rlG5NWipUrxSjI8nK",
        sports24:
          "U2FsdGVkX191YwDf49MfEnU4vhsgdWAcz6jMvZ7Ehedd2bxXd0G58x1RGiOvcEcT3eTiWRzKupun+rHk6fOANRgI3iGAbOdZf+L69kSRcuSiWXq4ukSsV4mt0AgEueWg",
        tbsnews:
          "U2FsdGVkX1+121o-fl-ydxePruS7Z6uXbLSuoUaUzSCqu3AdrxZkzgYgGEsN5LCNeWT4WaI4yzqsjMSiMAoBxfhfW56eUjwwbKfr6kK-fl-Qwp2YGxcEO+x0tiH14-fl-rVW3fKlQ",
        elevat:
          "U2FsdGVkX1+nwkASL1C+Bn13xH86S6Mh9v7THPuF8draTh7t4J3SMzFrFBzAU76s",
        demo1:
          "U2FsdGVkX1+I2i8RcYLZsMAf2940kvhSoP+5ThFtdIMP+FiUqOo7C+ngT-fl-pSk8RXrGEesAwArJ2fPlgrgRnDuM5e0lnMm37ofFuOgJR-fl-oxM=",
        olahbola:
          "U2FsdGVkX18xCAqp3QLpo6G8hjNX1ADJbGPodzRB71WIdJM+pHj7tcVEzorBFLL00vzn6RrIojm80ufOnfVkWVLqY+q-fl-zXGPuaiwcWf27zzS9JxnqMaeeaSOA6DrzQgp",
      },
      partnerNames: {
        footylight: "Demo",
        pavilion: "Pavilion",
        yoursoccerdose: "YourSoccerDose",
        soccercatch: "SoccerCatch",
        sports24: "Sports24",
        tbsnews: "The Business Standard",
        elevat: "Elevat",
        demo1: "Demo 1",
        olahbola: "OlahBola",
      },
      partnerPasswords: {
        footylight: "F00tyLight",
        pavilion: "P4v1L10N",
        yoursoccerdose: "Y0uR50cc3RD05E",
        soccercatch: "50cCeRC4tcH",
        sports24: "5p0Rt524",
        tbsnews: "TB5t4Nd4Rd",
        elevat: "3L3v4T",
        demo1: "FL-Demo-1",
        olahbola: "014HB014",
      },
      partnerName: "Demo",
      feeds: [
        //Latest
        { name: "Latest", id: "latest" },

        //Tournaments
        { name: "English Premier League", id: "tournament/1" },
        { name: "La Liga", id: "tournament/10" },
        { name: "Serie A", id: "tournament/9" },
        { name: "Bundesliga", id: "tournament/6" },
        { name: "Ligue 1", id: "tournament/5" },
        { name: "Champions League", id: "tournament/2" },
        { name: "Europa League", id: "tournament/3" },

        //Teams
        { name: "Manchester United", id: "team/1" },
        { name: "Manchester City", id: "team/27" },
        { name: "Arsenal", id: "team/22" },
        { name: "Leicester City", id: "team/36" },
        { name: "Liverpool", id: "team/2" },
        { name: "Tottenham Hotspur", id: "team/7" },
        { name: "Everton", id: "team/21" },
        { name: "Chelsea", id: "team/31" },
        { name: "Southampton", id: "team/25" },
        { name: "West Ham United", id: "team/8" },
        { name: "Aston Villa", id: "team/167" },
        { name: "Leeds United", id: "team/209" },
        { name: "Crystal Palace", id: "team/24" },
        { name: "Wolverhampton Wanderers", id: "team/165" },
        { name: "Newcastle United", id: "team/6" },
        { name: "FC Bayern Muenchen", id: "team/33" },
        { name: "RB Leipzig", id: "team/90" },
        { name: "Borussia Dortmund", id: "team/61" },
        { name: "FC Schalke 04", id: "team/105" },
        { name: "Atletico Madrid", id: "team/30" },
        { name: "Real Madrid", id: "team/32" },
        { name: "Barcelona", id: "team/4" },
        { name: "Villarreal", id: "team/51" },
        { name: "Sevilla", id: "team/55" },
        { name: "Real Sociedad", id: "team/52" },
        { name: "Valencia", id: "team/5" },
        { name: "Atalanta", id: "team/123" },
        { name: "AC Milan", id: "team/96" },
        { name: "Inter", id: "team/41" },
        { name: "Napoli", id: "team/18" },
        { name: "Roma", id: "team/74" },
        { name: "Juventus", id: "team/73" },
        { name: "Paris Saint Germain", id: "team/16" },
        { name: "Lyon", id: "team/23" },
        { name: "Monaco", id: "team/75" },
      ],
    };
  },
  mounted: function () {
    if (this.partnerKey) {
      this.partnerName = this.partnerNames[this.partnerKey];
    }
    this.getData();
  },
  methods: {
    checkPassword: function () {
      let partnerId = this.partnerKey;
      if (this.partnerKey === "" || !this.partnerKey) {
        partnerId = "footylight";
      }
      if (this.password === this.partnerPasswords[`${partnerId}`]) {
        console.log("Success");
        this.passwordMatched = true;
      } else {
        alert("Invalid Password");
      }
    },
    sendMail() {
      let data = {
        subject: "Email from: " + this.companyName + " " + this.websiteUrl,
        name: this.firstName,
        email: this.email,
        message:
          this.message 
      };
      console.log({ data });
      let config = {
        method: "post",
        url: "https://dev.footylight.com/api/send-email",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          alert("Thank you. Your Message has delivered to the footylight.com");
        })
        .catch(function (error) {
          console.log(error);
          alert("Sorry.Could not deliver you message");
        });
    },
    getData: function () {
      this.videos = [];
      if (this.partnerKey) {
        this.partnerId = this.partnerCodes[`${this.partnerKey}`];
      } else {
        this.partnerId = this.partnerCodes.footylight;
      }

      console.log(this.partnerId);

      fetch(
        "https://publisher-api.footylight.com/api/" +
          this.partnerId +
          "/feed/" +
          this.feed
      )
        .then((response) => response.json())
        .then((dt) => (this.videos = dt));
    },
    extractMp4(edata) {
      var i = edata.indexOf("data-mp4");
      var f = edata.indexOf('"', i + 10);
      return this.decryptData(edata.substring(i + 10, f));
    },

    decryptData(edata) {
      return this.CryptoJS.AES.decrypt(edata, "f00tyV1de0F33d").toString(
        this.CryptoJS.enc.Utf8
      );
    },
    copyToCB(e) {
      //document.querySelector(`${e.target.id}_code`).select();
      const copyTextarea = this.$refs[`${e.target.id}_code`];
      if (copyTextarea) {
        copyTextarea[0].focus();
        copyTextarea[0].select();
        document.execCommand("copy");
      }
    },
    openDemoModal(e) {
      const modalId = `#${e.target.id}_modal`;
      $(modalId).modal({});
    },
  },
};
</script>

<style>
#partner {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.embedcode {
  padding: 2px 16px;
  width: 90%;
  height: 100px;
}
</style>
