<template>
  <div id="app">
    <Partner 
      :partnerKey="$route.params.partnerKey"
      />
</div>

</template>

<script>
import Partner from './components/Partner.vue'

export default {
  name: 'App',
  components: {
    Partner
  },
  mounted: function() {
    
  },
}
</script>

<style>

</style>
